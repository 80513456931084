<template>
  <div class="c_caption" :class="captionHidden ? 'c_caption--hidden' : ''">
    <slot></slot>
    <div v-if="content" class="c_caption__content">
      <div class="c_caption__content__content" v-html="content"></div>
      <!-- <Button class="c_caption__content__close" @onClick="hideCaption" reset unstyle :aria-label="$t('Hide caption')">
        <IconSVG stroke-color-var="white" handle="close" aria-hidden="true" />
      </Button> -->
    </div>
  </div>
</template>

<script>
import { log } from 'JS/global.js';
// import Button from 'Components/button/Button.vue';
// import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    // Button,
    // IconSVG,
  },
  data() {
    return {
      captionHidden: false,
    };
  },
  props: {
    content: String,
  },
  computed: {},
  methods: {
    hideCaption() {
      log('hide caption fired');
      if (!this.captionHidden) {
        this.captionHidden = true;
      }
    },
  },
  created() {},
  mounted() {
    console.log('R');
  },
};
</script>

<style lang="scss">
.c_caption {
  $self: &;
  max-height: 100%;
  max-width: 900px;

  @at-root #{$self}__content {
    // background: rgba($color_black_raw, 0.8);
    color: $color_white;
    box-sizing: border-box;
    font-size: px_to_rem(16);
    display: flex;
    text-align: left;
    left: 0;
    margin: 0 auto 0 auto;
    padding-left: px_to_rem(15);
    padding-right: px_to_rem(15);
    line-height: px_to_rem(20);
    @at-root #{&}__content {
      padding-top: px_to_rem(30);
    }
    @at-root #{&}__close {
      width: px_to_rem(20);
      height: px_to_rem(20);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: px_to_rem(20);
    }

    opacity: 1;
    #{$self}--hidden & {
      opacity: 0;
      bottom: -100%;
    }
  }
}
</style>
